body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.layout {
  background: url(https://i.imgur.com/BIDhiYe.gif) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
  min-height: 100vh;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 600px) {
  html {
    min-height: 100%;
  }
}

a {
  text-decoration: none;
}

div {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.header {
  display: flex !important;
  justify-content: space-between !important;
}

.input-image {
  background-color: #f50057;
  margin: 20px;
  width: 250px;
}

.upload {
  margin: 0;
}
.social {
  display: flex;
  justify-content: center;
  width: 20%;
  margin: auto;
  color: black;
  font-size: 2em;
  padding: 4px;
}
.link {
  margin: 0.5rem;
  cursor: pointer;
}
.link:hover {
  transform: scale(1.1);
}
.banner {
  text-shadow: black 2px 2px;
  color: white;
}

.banner h1 {
  color: white;
  margin-bottom: 0px;
}

.banner h4 {
  color: whitesmoke;
  margin-top: 0px;
  margin-bottom: 0;
  animation: movetext 15s infinite alternate;
  -webkit-animation: movetext 15s infinite alternate;
}

@keyframes movetext {
  0% {
    letter-spacing: 5px;
  }
  100% {
    letter-spacing: 10px;
  }
}

@-webkit-keyframes movetext {
  0% {
    letter-spacing: 5px;
  }
  100% {
    letter-spacing: 10px;
  }
}

#shadow {
  -webkit-box-shadow: 0px 0px 20px 3px #000000 !important;
  box-shadow: 0px 0px 20px 3px #000000 !important;
}

.allDrinks {
  display: flex !important;
  justify-content: space-between !important;
}

.kortti {
  position: relative;
  text-align: center;
  color: black;
  width: 330px;
  height: 440px;
  margin: auto;
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 0px 11px 0px #000000 !important;
  box-shadow: 0px 0px 11px 0px #000000 !important;
}
.kortti h3 {
  color: "black";
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: block;
  font-size: 28px;
}
.kortti h4 {
  color: "black";
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  margin: 24px;
  display: block;
  font-size: 28px;
}

.kortti-img {
  height: 270px;
  max-width: 330px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.perus {
  text-align: center;
  width: 100%;
  color: white;
}

#totalDrinks {
  text-shadow: 0px 2px 4px #000000;
  color: white !important;
  margin: 10px 0px 0px 0px;
}
#alert-dialog-title {
  text-align: center;
  position: relative;
}

#samplekortti {
  margin-top: 30px;
}

.who {
  text-align: center;
  width: 100%;
  color: white;
}
.smaller_title {
  text-align: center;
  -webkit-text-stroke: 0px black;
  color: white;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  margin: 0px;
}

.title {
  text-align: center;
  -webkit-text-stroke: 1px black;
  color: white;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  margin-bottom: 20px !important;
}

.etusivu {
  background: linear-gradient(125deg, #cb21ff, #42aaff, #ffd00f);
  background-size: 300% 300%;

  -webkit-animation: GradientAnimation 40s ease infinite;
  animation: GradientAnimation 40s ease infinite;
}

@-webkit-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.villebot {
  color: white;
  background-color: #212121;
}

.presentation {
  text-align: "justify";
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translateX(-50%) translateY(-50%);
}

.presentationL {
  text-align: "justify";
  position: absolute;
  top: 10%;
  left: 10%;
}

@media only screen and (max-width: 1280px) {
  .presentation {
    display: none;
  }
  .presentationL {
    display: none;
  }
  .onMobile {
    display: block !important;
  }
}
.onMobile {
  display: none;
}
